export const isValidUrl = () => typeof window !== "undefined" && window.location.pathname.match(/^\/\w{2}-\w{2}\//g);

export const isOptInUrl = () => typeof window !== "undefined" && window.location.pathname === "/opt_in";

export const buildParamURL = (params: { [key: string]: string }) =>
  Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join("&");

export const parseHashParams = (url: string) => {
  const pieces = url.replace(/^#/g, "").split("&");
  const data: Record<string, string> = {};
  let parts;
  for (let i = 0; i < pieces.length; i += 1) {
    parts = pieces[i].split("=");
    if (parts.length < 2) {
      parts.push("");
    }
    data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
  }
  return data;
};

export const stripLocale = (url: string) => url.replace(/^\/\w{2}-\w{2}\//g, "");
