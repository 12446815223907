import React, { useRef, useCallback } from "react";
import DetachedModal from "../../layout/DetachedModal/DetachedModal";
import YoutubeVideo from "../../general/YoutubeVideo/YoutubeVideo";
import useGlobal from "../../../store";
import styles from "./YoutubeModal.module.scss";

const YoutubeModal: React.FC = () => {
  const popupRef = useRef<HTMLDivElement>(null);
  const [{ youtubeModalVideoId }, { setYoutubeModalVideoId }] = useGlobal();

  const handleModalCloseClick = useCallback(() => {
    setYoutubeModalVideoId("");
  }, [setYoutubeModalVideoId]);

  return (
    <DetachedModal
      open={!!youtubeModalVideoId}
      popupRef={popupRef}
      isYoutubePopup={true}
      onClickOutside={handleModalCloseClick}
      onCloseButtonClick={handleModalCloseClick}
    >
      <div className={styles.popupInnerWrapper} ref={popupRef}>
        <YoutubeVideo videoId={youtubeModalVideoId} isModal={true} />
      </div>
    </DetachedModal>
  );
};
export default YoutubeModal;
