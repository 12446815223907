export const isIE = (): boolean => {
  if (typeof window !== "undefined" && window.navigator && window.navigator.userAgent) {
    const ua = window.navigator.userAgent;
    return ua.indexOf("MSIE ") !== -1 || ua.indexOf("Trident/") !== -1;
  }

  return false;
};

export const isEdge = (): boolean => {
  if (typeof window !== "undefined" && window.navigator && window.navigator.userAgent) {
    const ua = window.navigator.userAgent;
    return ua.indexOf("Edge/") !== -1;
  }

  return false;
};

export const isMS = (): boolean => {
  return isIE() || isEdge();
};

export const hasTouch = (): boolean => {
  if (typeof window !== "undefined") {
    return "ontouchstart" in window;
  }

  return false;
};
