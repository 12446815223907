import { icons } from "./icons";
import styles from "./Icon.module.scss";
import React, { useMemo } from "react";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
  name: keyof typeof icons;
  useCurrentColor?: boolean;
  innerRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const Icon: React.FunctionComponent<Props> = ({
  name,
  size,
  useCurrentColor = true,
  className,
  innerRef,
  ...props
}) => {
  const dimensions = useMemo(() => (size ? { width: size, height: size } : undefined), [size]);

  const selectedIcon = useMemo(() => {
    const Icon = icons[name];
    return !Icon ? null : <Icon />;
  }, [name]);

  if (process.env.NODE_ENV === "development" && !Icon) {
    console.warn(`No such icon: ${Icon}`);
  }

  return !selectedIcon ? null : (
    <div
      ref={innerRef}
      className={`icon ${className} ${styles.icon} ${useCurrentColor ? styles.currentColor : ""}`}
      style={dimensions}
      {...props}
    >
      {selectedIcon}
    </div>
  );
};

export default Icon;
