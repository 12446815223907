import { Store } from "use-global-hook";
import decode from "jwt-decode";
import siteConfig from "../../config/siteConfig";
import { fetchUserinfo } from "../../services/rso";
import { StoreState, StoreActions } from "..";
// import rsoConfig from "../../services/rso/config";
// import { AppActions, AppState } from "..";

interface UserActions {
  setUserState: () => void;
}

export interface UserState {
  loading: boolean;
  signedIn: boolean;
  entitled: boolean;
  name: string;
}

// initialState
const initialState = {
  user: {
    loading: true,
    signedIn: false,
    entitled: false,
    name: "",
  },
};

// actions
const setUserState = async (store: Store<StoreState, StoreActions>) => {
  const user: UserState = {
    ...store.state.user,
  };

  const token = sessionStorage.getItem(siteConfig.localStorageAuthToken);

  if (token) {
    user.loading = true;
    store.setState({
      ...store.state,
      user,
    });

    // eslint-disable-next-line @typescript-eslint/camelcase
    const { access_token } = JSON.parse(token);

    try {
      // pushing to datalayer puuid from rso token
      const { sub } = decode(access_token);
      window.dataLayer.push({
        puuid: sub,
      });
    } catch (error) {
      // no-op
    }

    /** User info */
    try {
      const userinfo = await fetchUserinfo(access_token);
      user.name = userinfo["acct"]["game_name"];
    } catch (e) {
      // Username was not available. No need to report the error
    }

    window.RiotBar.account.setAuthState({
      isAuthenticated: true,
      name: user.name,
    });
  }

  user.loading = false;
  store.setState({
    ...store.state,
    user,
  });
};

export default {
  actions: {
    setUserState,
  },
  initialState,
};
