import React from "react";
import Icon from "../Icon/Icon";
import styles from "./Loader.module.scss";
import { ColorTheme } from "../../../data/enum/ColorTheme";

interface LoaderProps {
  className?: string;
  theme?: ColorTheme;
  relativeSize?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ className = "", theme = ColorTheme.LIGHT, relativeSize }) => (
  <Icon
    className={`${className} ${styles.loader} ${styles[theme]} ${relativeSize ? styles.relativeSize : ""}`}
    name="WildRift"
  />
);

export default Loader;
