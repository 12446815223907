/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/interface-name-prefix */
interface IRSOConfig {
  authorization_endpoint: string;
  userinfo_endpoint: string;
  end_session_endpoint: string;
  token_endpoint: string;
}

declare const SITE_CONFIG_RSO_CLIENT_ID: string;
declare const SITE_CONFIG_OPT_IN_ORIGIN: string;
declare const SITE_CONFIG_RSO_ORIGIN: string;
// declare const SITE_CONFIG_ENTITLEMENTS_ORIGIN: string;

export default class RSOConfig {
  // Configuration constants.
  // public static readonly ENTITLEMENTS_ORIGIN = SITE_CONFIG_ENTITLEMENTS_ORIGIN;
  public static readonly RSO_CLIENT_ID = SITE_CONFIG_RSO_CLIENT_ID;
  public static readonly OPT_IN_ORIGIN = SITE_CONFIG_OPT_IN_ORIGIN;
  public static readonly RSO_ORIGIN = SITE_CONFIG_RSO_ORIGIN;

  private static _instance: RSOConfig;
  private config: IRSOConfig | undefined;

  public static get instance(): RSOConfig {
    return this._instance || (this._instance = new this());
  }

  public async get(): Promise<IRSOConfig> {
    if (this.config) {
      return this.config;
    }

    try {
      const response = await fetch(`${RSOConfig.RSO_ORIGIN}/.well-known/openid-configuration`);
      const body = await response.json();
      this.config = { ...body };
    } catch (e) {
      this.config = RSOConfig.fallback();
    }

    return this.config as IRSOConfig;
  }

  private static fallback(): IRSOConfig {
    return {
      authorization_endpoint: `${RSOConfig.RSO_ORIGIN}/authorize`,
      userinfo_endpoint: `${RSOConfig.RSO_ORIGIN}/userinfo`,
      end_session_endpoint: `${RSOConfig.RSO_ORIGIN}/logout`,
      token_endpoint: `${RSOConfig.RSO_ORIGIN}/token`,
    };
  }
}
