import { randomString } from "../../utils/string";
import { buildParamURL } from "../../utils/url";
import { BCPLocale, RiotLocale, bcp2rso } from "../../helpers/rioti18n";
import config from "./config";

export enum RSOPrompt {
  LOGIN = "login",
  SIGNUP = "signup",
  PREREGISTRATION = "preregistration",
}

export type TRSOOptinStatus = "OPTED_IN" | "OPTED_OUT";

export interface Entitlement {
  entitlements: Array<string>;
  at_hash: string;
  sub: string; // puuid
  iss: string;
  iat: number;
  jti: string;
}

export interface Optin {
  playerName: string;
  isEntitled: boolean;
}

export async function createAuthorizationUrl(
  locale: BCPLocale,
  prompt: RSOPrompt,
  redirect_uri?: string,
  stateArgs: string = randomString(32),
) {
  const { authorization_endpoint } = await config.instance.get();
  const state = btoa(stateArgs);
  const nonce = btoa(randomString(32)).slice(0, 16);
  const ui_locales = bcp2rso(locale);
  const params = {
    state,
    nonce,
    prompt,
    ui_locales,
    client_id: config.RSO_CLIENT_ID,
    response_type: "token id_token",
    scope: "account openid",
    ...(redirect_uri && { redirect_uri }),
    // ...(prompt === RSOPrompt.SIGNUP && { acr_values: 'urn:riot:gold' }),
  };

  return {
    nonce,
    authorizationUrl: `${authorization_endpoint}?${buildParamURL(params)}`,
  };
}

export async function doOptin(token: string, locale: RiotLocale): Promise<Entitlement> {
  const response = await fetch(config.OPT_IN_ORIGIN, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      locale,
      status: "OPTED_IN",
    }),
  });
  return await response.json();
}

export async function fetchUserinfo(token: string): Promise<any> {
  const response = await fetch((await config.instance.get()).userinfo_endpoint, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Referer: `https://${window.location.hostname}/`,
    },
  });
  return (await response.json()) as any; // FYI this will return some user info. Not sure what the object looks like yet
}

// export async function fetchEntitlements(token: string): Promise<Entitlement> {
//   const response = await fetch(config.ENTITLEMENTS_ORIGIN, {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${token}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const { entitlements_token } = await response.json();
//   return decode(entitlements_token) as Entitlement;
// }
