import React, { useContext, useCallback, useState, useRef } from "react";
import useGlobal from "../../../store";
import Locale from "../../../data/contexts/riot-locale";
import DetachedModal from "../../layout/DetachedModal/DetachedModal";
import styles from "./RegistrationModal.module.scss";
import { createAuthorizationUrl, RSOPrompt } from "../../../services/rso";
import siteConfig from "../../../config/siteConfig";
import PrimaryButton from "../../general/button/PrimaryButton/PrimaryButton";
import { ColorTheme } from "../../../data/enum/ColorTheme";
import { ContentstackAppstoreContent, ContentstackCTA } from "../../../data/types/Contentstack";
import { trackRegistrationEvent, Platform } from "../../../utils/analytics";
import Submit, { SubmitState } from "../../../data/enum/Submit";
import Loader from "../../general/Loader/Loader";

export interface RegistrationModalProps {
  android: ContentstackAppstoreContent<ContentstackCTA>;
  ios: ContentstackAppstoreContent<Array<ContentstackCTA>>;
}

const RegistrationModal: React.FC<RegistrationModalProps> = ({ android, ios }) => {
  const [{ registrationModalActiveState }, { setRegistrationModalActiveState }] = useGlobal();
  const [submitState, setSubmitState] = useState<SubmitState>(Submit.IDLE);
  const locale = useContext(Locale);
  const popupRef = useRef<HTMLDivElement>(null);

  // click handler for signup / login buttons
  const handleRegistrationButtonClick = useCallback(
    async (prompt: RSOPrompt) => {
      trackRegistrationEvent(prompt, Platform.IOS);
      const { authorizationUrl, nonce } = await createAuthorizationUrl(
        locale,
        prompt,
        `${window.location.origin}/${locale}/opt_in/`,
        prompt,
      );

      window.localStorage.setItem(siteConfig.rsoNonce, nonce);
      window.location.assign(authorizationUrl);
    },
    [locale],
  );

  const onSignupButtonClick = useCallback(() => {
    setSubmitState(Submit.SUBMITTING);
    handleRegistrationButtonClick(RSOPrompt.SIGNUP);
  }, [handleRegistrationButtonClick]);
  const onLoginButtonClick = useCallback(() => {
    setSubmitState(Submit.SUBMITTING);
    handleRegistrationButtonClick(RSOPrompt.LOGIN);
  }, [handleRegistrationButtonClick]);
  const onPreRegisterButtonClick = useCallback(() => {
    trackRegistrationEvent(RSOPrompt.PREREGISTRATION, Platform.ANDROID);
  }, []);

  const handleModalCloseClick = useCallback(() => {
    registrationModalActiveState && setRegistrationModalActiveState(false);
  }, [registrationModalActiveState, setRegistrationModalActiveState]);

  return (
    <DetachedModal
      open={registrationModalActiveState}
      onClickOutside={handleModalCloseClick}
      onCloseButtonClick={handleModalCloseClick}
      popupRef={popupRef}
    >
      <div className={styles.popupInnerWrapper} ref={popupRef}>
        <span className={styles.croppedBackground} />
        <span className={styles.dashDetailTop} />
        <span className={styles.dashDetailLeft} />
        <div className={styles.registrationOptionsWrapper}>
          {!!android && (
            <div className={styles.registrationOption}>
              <h3 className={`heading-02 ${styles.registrationOptionTitle}`}>{android.title}</h3>
              <p className={`copy-01 ${styles.registrationDescription}`}>{android.description}</p>
              <div className={styles.ctaWrapper}>
                <PrimaryButton
                  label={android.cta.title}
                  className={styles.registrationButton}
                  href={android.cta.href}
                  theme={ColorTheme.DARK}
                  onClick={onPreRegisterButtonClick}
                />
              </div>
            </div>
          )}
          {!!ios && ios.cta.length === 2 && (
            <div className={styles.registrationOption}>
              <span className={styles.lineDetail} />
              <h3 className={`heading-02 ${styles.registrationOptionTitle}`}>{ios.title}</h3>
              <p className={`copy-01 ${styles.registrationDescription}`}>{ios.description}</p>
              <div className={styles.ctaWrapper}>
                {submitState !== Submit.SUBMITTING ? (
                  <>
                    <PrimaryButton
                      label={ios.cta[0].title}
                      className={styles.registrationButton}
                      onClick={onLoginButtonClick}
                      theme={ColorTheme.DARK}
                    />
                    <PrimaryButton
                      label={ios.cta[1].title}
                      className={styles.registrationButton}
                      onClick={onSignupButtonClick}
                      theme={ColorTheme.LIGHT}
                    />
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </DetachedModal>
  );
};

export default RegistrationModal;
