import { BCPLocale } from "../helpers/rioti18n";
import { AllRegionCode, Region } from "../data/enum/Region";

type SiteConfig = {
  rsoNonce: string;
  localStorageAuthToken: string;
  defaultLocale: BCPLocale;
  defaultRegion: AllRegionCode;
  riotbarEnv: string;
};

const siteConfig: SiteConfig = {
  rsoNonce: process.env.GATSBY_RSO_NONCE ?? "wildrift.rso:nonce",
  localStorageAuthToken: process.env.GATSBY_LOCALSTORAGE_AUTH_TOKEN ?? "wildrift.auth",
  defaultLocale: (process.env.GATSBY_DEFAULT_LOCALE as BCPLocale) ?? BCPLocale["vi-vn"],
  defaultRegion: (process.env.GATSBY_DEFAULT_REGION as AllRegionCode) ?? (Region.VI as AllRegionCode),
  riotbarEnv: process.env.GATSBY_RIOTBAR_ENV ?? "staging",
};

export default siteConfig;
