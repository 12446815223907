import { gsap, Power1 } from "gsap";

export const animateButton = (buttonElement: Element | null) => {
  if (buttonElement) {
    const topAnimatedBorder = buttonElement.firstElementChild;
    const backgroundElement = buttonElement.childNodes[1];
    const bottomAnimatedBorder = buttonElement.lastElementChild;
    const duration = 0.3;

    const tl = gsap.timeline({
      paused: true,
      defaults: { duration, ease: Power1.easeInOut },
    });

    tl.set(buttonElement, { css: { visibility: "visible" } })
      .to(topAnimatedBorder, { width: "100%" }, 0)
      .to(topAnimatedBorder, { height: "100%" }, duration)
      .to(bottomAnimatedBorder, { width: "100%" }, 0)
      .to(bottomAnimatedBorder, { height: "100%" }, duration)
      .fromTo(
        backgroundElement,
        {
          opacity: 0,
        },
        {
          duration: 0.5,
          opacity: 1,
          ease: Power1.easeOut,
        },
      )
      .set(topAnimatedBorder, { css: { display: "none" } })
      .set(bottomAnimatedBorder, { css: { display: "none" } })
      .set(buttonElement, { css: { pointerEvents: "all" } });

    return tl;
  }
};
