import { useEffect, useState } from "react";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const setMediaMatchHandler = (event: MediaQueryListEvent | MediaQueryList): void => {
      setIsMobile(event.matches);
    };

    const mediaQueryList = window.matchMedia("(max-width: 1023px)");

    setMediaMatchHandler(mediaQueryList);

    mediaQueryList.addListener(setMediaMatchHandler);

    return () => mediaQueryList.removeListener(setMediaMatchHandler);
  }, []);

  return isMobile;
};
