import { gsap } from "gsap";
import React, { useEffect, useMemo, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { isMS } from "../../../utils/browser-detect";
import styles from "./Video.module.scss";

interface VideoMP4 {
  mp4Url: string;
  webmUrl?: string;
  posterUrl?: string;
}

interface VideoWebM {
  mp4Url?: string;
  webmUrl: string;
  posterUrl?: string;
}

interface VideoProps {
  source: VideoWebM | VideoMP4;
  mobileSource?: VideoWebM | VideoMP4;
  className?: string;
  fadeOutPosterOnPlay?: boolean;
  autoplay?: boolean;
  showControls?: boolean;
}

export const Video: React.FC<VideoProps> = ({
  source,
  mobileSource,
  className = "",
  fadeOutPosterOnPlay = true,
  autoplay = true,
  showControls = false,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const posterRef = useRef<HTMLDivElement>(null);

  const isMobile = useIsMobile();

  const [videoWrapperRef, inView] = useInView({
    threshold: 0,
  });

  const handleResize = () => {
    const videoEl = videoRef.current;

    if (isMS()) {
      if (videoEl && videoEl.videoWidth > 0) {
        videoEl.style.width = "100%";
        videoEl.style.height = "100%";

        const { videoWidth, videoHeight, offsetWidth, offsetHeight } = videoEl;

        if (offsetWidth / offsetHeight > videoWidth / videoHeight) {
          videoEl.style.width = `${offsetWidth}px`;
          videoEl.style.height = `${offsetWidth * (videoHeight / videoWidth)}px`;
        } else {
          videoEl.style.width = `${offsetHeight * (videoWidth / videoHeight)}px`;
          videoEl.style.height = `${offsetHeight}px`;
        }
      }
    }
  };

  const onPlayHandler = () => {
    if (isMS()) handleResize();

    if (posterRef.current) {
      gsap.to(posterRef.current, {
        opacity: fadeOutPosterOnPlay ? 0 : 1,
        duration: 1,
        delay: 0.1,
      });
    }
  };

  const videoPoster = useMemo(
    () => (isMobile && mobileSource?.posterUrl ? mobileSource?.posterUrl : source.posterUrl),
    [isMobile, mobileSource?.posterUrl, source.posterUrl],
  );

  const videoSource = useMemo(() => {
    const mp4Source = isMobile && mobileSource?.mp4Url ? mobileSource?.mp4Url : source.mp4Url;
    const webmSource = isMobile && mobileSource?.webmUrl ? mobileSource?.webmUrl : source.webmUrl;

    return { mp4: mp4Source, webm: webmSource };
  }, [isMobile, mobileSource?.mp4Url, mobileSource?.webmUrl, source.mp4Url, source.webmUrl]);

  useEffect(() => {
    if (inView && autoplay) {
      const playPromise = videoRef.current?.play();

      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.warn(error); // eslint-disable-line no-console
        });
      }
    } else {
      videoRef.current?.pause();
    }
  }, [inView, autoplay]);

  return (
    <div ref={videoWrapperRef} className={`${className} ${styles.video}`}>
      <video ref={videoRef} preload="auto" muted loop playsInline onPlay={onPlayHandler} controls={showControls}>
        {videoSource.webm && <source src={videoSource.webm} type="video/webm" />}
        {videoSource.mp4 && <source src={videoSource.mp4} type="video/mp4" />}
      </video>
      <div
        ref={posterRef}
        className={styles.poster}
        style={{ pointerEvents: "none", backgroundImage: `url(${videoPoster})` }}
      />
    </div>
  );
};
