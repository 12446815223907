export default {
  read: (name: string): string | null => {
    if (typeof document !== `undefined`) {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
    }
    return null;
  },
  write: (name: string, value: string, expires: Date) => {
    if (typeof document !== `undefined`) {
      try {
        document.cookie = name + "=" + value + ";path=/;expires=" + expires.toUTCString();
      } catch (err) {
        // noop
      }
    }
    return null;
  },
};
