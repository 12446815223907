export enum ColorTheme {
  DARK = "dark",
  LIGHT = "light",
  ELECTRIC = "electric",
}

export type ColorThemeOption = ColorTheme.LIGHT | ColorTheme.DARK | ColorTheme.ELECTRIC;

export enum ButtonColorTheme {
  DARK = "dark",
  DEFAULT = "default",
  LIGHT = "light",
  WHITE = "white",
}

export type ButtonColorThemeOption =
  | ButtonColorTheme.DEFAULT
  | ColorTheme.LIGHT
  | ColorTheme.DARK
  | ButtonColorTheme.WHITE;
