// ueslint-plugin-react-hooks naming issues with "useGlobalHook"
// https://github.com/andregardi/use-global-hook/issues/20
import globalHook from "use-global-hook";
import React from "react";
import app, { AppStoreSection } from "./module/app";
import user from "./module/user";
import { UserState } from "./module/user";

// Defining your own state and associated actions is required
export type StoreState = {
  registrationModalActiveState: boolean;
  youtubeModalVideoId: string;
  appStoreSection: AppStoreSection;
  user: UserState;
};

// Associated actions are what's expected to be returned from globalHook
export type StoreActions = {
  setRegistrationModalActiveState: (active: boolean) => void;
  setYoutubeModalVideoId: (id: string) => void;
  setAppStoreData: (appStoreSection: AppStoreSection) => void;
  setUserState: () => void;
};

const initialState: StoreState = {
  ...app.initialState,
  ...user.initialState,
};

const actions = {
  ...app.actions,
  ...user.actions,
};

const useGlobal = globalHook<StoreState, StoreActions>(React, initialState, actions);

export default useGlobal;
