import { RSOPrompt } from "../services/rso";

export enum Platform {
  IOS = "ios",
  ANDROID = "android",
}

export const InteractionId = {
  HERO: {
    GOOGLE: "hero-google_play",
    APPLE: "hero-apple_app",
    GALAXY: "hero-galaxy_app",
  },
  PREFOOTER: {
    GOOGLE: "prefooter-google_play",
    APPLE: "prefooter-apple_app",
  },
};

const track = (trackingDetails: Record<string, string>) => {
  try {
    window.dataLayer.push(trackingDetails);
    return true;
  } catch (error) {
    console.warn(`GA ${!!trackingDetails.registrationMethod ? "Registration" : ""} Event Error: ${error}`);
    return false;
  }
};

export const trackEvent = (event: string) => {
  track({ event });
};

export const trackRegistrationEvent = (registrationMethod: RSOPrompt, platform: Platform) => {
  track({ event: "registrationBtnClick", registrationMethod, platform }) &&
    window.localStorage.setItem("registrationBtnClick", registrationMethod);
};

export const trackInteraction = (dataInteractionId: string) => {
  track({ "data-interaction-id": dataInteractionId });
};
