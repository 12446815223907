import { ReactComponent as ArticleExternalLink } from "../../../assets/svg/article-types-external-link.svg";
import { ReactComponent as ArticleNormalArticle } from "../../../assets/svg/article-types-normal-article.svg";
import { ReactComponent as ArticleYoutube } from "../../../assets/svg/article-types-youtube.svg";
import { ReactComponent as BlueBullet } from "../../../assets/svg/blue-bullet.svg";
import { ReactComponent as CarouselBlockDesktopFrame } from "../../../assets/svg/carousel-block-desktop-frame.svg";
import { ReactComponent as CarouselBlockMobileFrame } from "../../../assets/svg/carousel-block-mobile-frame.svg";
import { ReactComponent as Chevron } from "../../../assets/svg/chevron.svg";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import { ReactComponent as CustomCursor } from "../../../assets/svg/custom-cursor.svg";
import { ReactComponent as Diamond } from "../../../assets/svg/diamond.svg";
import { ReactComponent as Discord } from "../../../assets/svg/discord.svg";
import { ReactComponent as Ellipse } from "../../../assets/svg/ellipse.svg";
import { ReactComponent as Facebook } from "../../../assets/svg/facebook.svg";
import { ReactComponent as GalleryBlockDesktopFrame } from "../../../assets/svg/gallery-block-desktop-frame.svg";
import { ReactComponent as GalleryBlockMobileFrame } from "../../../assets/svg/gallery-block-mobile-frame.svg";
import { ReactComponent as Input } from "../../../assets/svg/input.svg";
import { ReactComponent as Instagram } from "../../../assets/svg/instagram.svg";
import { ReactComponent as LongArrow } from "../../../assets/svg/long-arrow.svg";
import { ReactComponent as MobileSliderFrame } from "../../../assets/svg/mobile-img-slider-frame.svg";
import { ReactComponent as PlayCircleThin } from "../../../assets/svg/play-circle-thin.svg";
import { ReactComponent as PlayCircle } from "../../../assets/svg/play-circle.svg";
import { ReactComponent as SlideFrame } from "../../../assets/svg/slide-frame.svg";
import { ReactComponent as Twitch } from "../../../assets/svg/twitch.svg";
import { ReactComponent as Twitter } from "../../../assets/svg/twitter.svg";
import { ReactComponent as Vk } from "../../../assets/svg/vk.svg";
import { ReactComponent as WildRift } from "../../../assets/svg/wildrift.svg";
import { ReactComponent as Youtube } from "../../../assets/svg/youtube.svg";
import { ReactComponent as Bell } from "../../../assets/svg/bell.svg";
import { ReactComponent as Reddit } from "../../../assets/svg/reddit.svg";
import { ReactComponent as Link } from "../../../assets/svg/link.svg";
import { ReactComponent as ChampsFilterFrameRight } from "../../../assets/svg/champs-filter-frame-right.svg";
import { ReactComponent as ChampsFilterFrameLeft } from "../../../assets/svg/champs-filter-frame-left.svg";
import { ReactComponent as ThumbnailCircle } from "../../../assets/svg/thumbnail-circle.svg";

export const icons = {
  Link,
  Instagram,
  Twitch,
  Facebook,
  Twitter,
  Youtube,
  Reddit,
  Vk,
  Discord,
  Close,
  Chevron,
  Diamond,
  Ellipse,
  WildRift,
  PlayCircle,
  PlayCircleThin,
  SlideFrame,
  MobileSliderFrame,
  ChampsFilterFrameRight,
  ChampsFilterFrameLeft,
  LongArrow,
  CustomCursor,
  GalleryBlockMobileFrame,
  GalleryBlockDesktopFrame,
  CarouselBlockMobileFrame,
  CarouselBlockDesktopFrame,
  BlueBullet,
  Input,
  ArticleExternalLink,
  ArticleYoutube,
  ArticleNormalArticle,
  Bell,
  ThumbnailCircle,
};
