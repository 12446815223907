import React, { FunctionComponent, useMemo, useCallback } from "react";
import { ContentstackVideo } from "../../../data/types/Contentstack";
import { Video } from "../../general/Video/Video";
import styles from "./GameRegistration.module.scss";
import useGlobal from "../../../store";
import { trackInteraction, InteractionId } from "../../../utils/analytics";

export interface GameRegistrationProps {
  videos: Array<ContentstackVideo>;
}

export const GameRegistration: FunctionComponent<GameRegistrationProps> = ({ videos }) => {
  const [
    {
      appStoreSection: { appStoreData },
    },
  ] = useGlobal();

  const randomVideo = useMemo(() => videos[Math.round(Math.random() * (videos.length - 1))], [videos]);

  const onGoogleButtonClick = useCallback(() => {
    trackInteraction(InteractionId.PREFOOTER.GOOGLE);
  }, []);

  const onAppleButtonClick = useCallback(() => {
    trackInteraction(InteractionId.PREFOOTER.APPLE);
  }, []);

  return (
    <section className={styles.gameRegistration}>
      <Video
        className={styles.gameRegistrationVideo}
        source={{ mp4Url: randomVideo.video.file.url, posterUrl: randomVideo.posterImage.url }}
      />
      <div className={`container ${styles.mainContainer}`}>
        <div className={`content-wrapper light ${styles.contentWrapper}`}>
          <div className={styles.storesWrapper}>
            {appStoreData.isGoogleEnabled ? (
              <a
                id="taigame"
                className={styles.appButton}
                href={appStoreData.googleStoreUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onGoogleButtonClick}
              >
                <img src={appStoreData.googleStoreBadge.url} />
              </a>
            ) : (
                <div className={`${styles.appButton} ${styles.disabled}`}>
                  <img src={appStoreData.googleStoreBadge.url} />
                </div>
              )}
            {appStoreData.isAppleEnabled ? (
              <a
                id="taigame"
                className={styles.appButton}
                href={appStoreData.appleStoreUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onAppleButtonClick}
              >
                <img src={appStoreData.appleStoreBadge.url} />
              </a>
            ) : (
                <div className={`${styles.appButton} ${styles.disabled}`}>
                  <img src={appStoreData.appleStoreBadge.url} />
                </div>
              )}
          </div>
        </div>
      </div>
    </section>
  );
};
