import { navigate } from "@reach/router";
import { navigate as localizedNavigate } from "gatsby-plugin-intl";

/* 
  THIS ONLY WORKS ON DESKTOP AS THERE'S NO RELIABLE WAY
  OF CLOSING THE SIDEBAR AFTER PRESSING A LINK ON MOBILE.
*/

const getAnchorElements = () => {
  const logoLink = document.querySelector(
    "[data-interaction-id='riotbar_app-switcher_theme-logo']",
  ) as HTMLAnchorElement | null;

  const navbarLinks = [...document.querySelectorAll(".riotbar-navbar-link")] as Array<HTMLAnchorElement | null>;

  return {
    logoLink,
    navbarLinks,
  };
};

export const convertAnchorsToNavigateLinks = () => {
  const { logoLink, navbarLinks } = getAnchorElements();
  const internalLinks = navbarLinks.filter((link) => link?.href && !link?.target);

  if (logoLink) {
    logoLink.onclick = function (event) {
      event.preventDefault();
      localizedNavigate("/");
    };
  }

  internalLinks.forEach((link) => {
    if (!link) return;
    link.onclick = function (event) {
      event.preventDefault();

      const relativeURL = new URL((event.target as HTMLAnchorElement).href).pathname;
      navigate(relativeURL);
    };
  });
};
