/*
  Layout.tsx needs to live under src/components/ for the static query to work
  https://github.com/gatsbyjs/gatsby/issues/24131
*/

import styles from "./layout/Layout/Layout.module.scss";
import RegistrationModal, { RegistrationModalProps } from "./block/RegistrationModal/RegistrationModal";
import {
  ContentstackAppstoreData,
  ContentstackPageMetadata,
  ContentstackNewsArticleMetadata,
  ContentstackAsset,
  ContentstackChampionMetadata,
} from "../data/types/Contentstack";
import React, { useMemo, useEffect, useContext } from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import { RiotBar } from "./block/RiotBar/RiotBar";
import { BCPLocale, isBCPLocale } from "../helpers/rioti18n";
import { GameRegistrationProps, GameRegistration } from "./block/GameRegistration/GameRegistration";
import useGlobal from "../store";
import { isValidUrl, isOptInUrl, stripLocale } from "../utils/url";
import siteConfig from "../config/siteConfig";
import Locale from "../data/contexts/riot-locale";
import { stripSlashes } from "../utils/string";
import { SEO } from "./seo";
import YoutubeModal from "./content/YoutubeModal/YoutubeModal";
import pageData from '../json/page-data.json';
import websiteConfig from '../json/website-config.json';
import logo_vng from "../assets/images/logo_vng.png";

interface WebsiteConfigurationProps {
  appStoreData: ContentstackAppstoreData;
  registrationCtaText: string;
  registrationCtaEnabled: boolean;
  registrationModal: RegistrationModalProps;
  gameRegistration: GameRegistrationProps;
  locale: BCPLocale;
}

interface LayoutQuery {
  allContentstackWebsiteConfiguration: {
    nodes: Array<WebsiteConfigurationProps>;
  };
  contentstackWebsiteConfiguration: {
    favicon: ContentstackAsset;
  };
}

export interface LayoutData {
  pageMetadata?: ContentstackPageMetadata;
  articleMetadata?: ContentstackNewsArticleMetadata;
  championMetadata?: ContentstackChampionMetadata;
}

export interface LayoutContext {
  language: string;
}

type LayoutProps = Omit<PageProps<LayoutData, LayoutContext>, "children">;

export const Layout: React.FC<LayoutProps> = ({ children, path, location, pageContext: { language }, data }) => {
  // data = pageData.result.data
  const [, { setAppStoreData }] = useGlobal();
  const [, { setUserState }] = useGlobal();

  const locale = useContext(Locale);
  const {
    allContentstackWebsiteConfiguration,
    contentstackWebsiteConfiguration: { favicon },
  } = websiteConfig.data

  const hideGameRegistration = useMemo(() => path.endsWith("/*") || path.endsWith("/confirmation/"), [path]);

  const localizedLayoutData = useMemo(() => {
    const layoutData = allContentstackWebsiteConfiguration.nodes.find(
      (node: WebsiteConfigurationProps) => node.locale === language,
    );

    return layoutData
      ? layoutData
      : allContentstackWebsiteConfiguration.nodes.find(
        (node: WebsiteConfigurationProps) => node.locale === siteConfig.defaultLocale,
      );
  }, [allContentstackWebsiteConfiguration, language]);

  useEffect(() => {
    const hasUrlLocale = isValidUrl();
    const urlLocale = hasUrlLocale && stripSlashes(hasUrlLocale[0]);

    if (!hasUrlLocale && !isOptInUrl()) {
      window.location.replace(`/${locale}${window.location.pathname}${window.location.search}`.replace(/\/{2,}$/, "/"));
    } else if (urlLocale && !isBCPLocale(urlLocale)) {
      window.location.replace(
        `/${locale}/${stripLocale(window.location.pathname)}${window.location.search}`.replace(/\/{2,}$/, "/"),
      );
    } else if (window.location.href.match(/\/{2,}$/)) {
      window.location.replace(window.location.href.replace(/\/{2,}$/, "/"));
    }
  }, [locale]);

  useEffect(() => {
    localizedLayoutData &&
      setAppStoreData({
        appStoreData: localizedLayoutData.appStoreData,
        ctaText: localizedLayoutData.registrationCtaText,
        ctaEnabled: localizedLayoutData.registrationCtaEnabled,
      });
  }, [localizedLayoutData, setAppStoreData]);

  useEffect(() => {
    document.addEventListener("riotbar_onload", () => {
      setUserState();
    });
  }, [setUserState]);

  var isSupportPage = typeof window !== `undefined` && window.location.pathname.match("hotrokhachhang") ? true : false;

  return (
    <div className={styles.layout}>
      <RiotBar locale={language} />
      <SEO lang={language} location={location} favicon={favicon} pageData={data} />
      <main>{children}</main>
      {localizedLayoutData && !isSupportPage && (
        <>
          {!hideGameRegistration && <GameRegistration videos={localizedLayoutData.gameRegistration.videos} />}
          <YoutubeModal />
          <RegistrationModal
            android={localizedLayoutData.registrationModal.android}
            ios={localizedLayoutData.registrationModal.ios}
          />
        </>
      )}
      <div>
        <div
          className="riotbar-footer i18n-en_US i18n-en"
          lang="en"
          style={{ marginTop: 0 }}>
          <div className="riotbar-footer-content default-background">
            <div className="riotbar-footer-logos">
              <div className="riotbar-footer-riot-logo">
                <a href="#" target="_blank">
                  <svg
                    width="109"
                    height="35"
                    viewBox="0 0 109 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.7136 31.6814L16.1378 27.5918L31.3569 28.3812L30.6733 34.9265L17.7136 31.6814Z"
                      fill="#E6E6E6"></path>
                    <path
                      d="M21.3595 0L0.0616455 10.143L3.35453 25.427H7.70011L6.9491 14.4946L7.61666 14.2843L10.2291 25.427H14.7416L14.5747 12.1101L15.2423 11.8998L17.5242 25.427H22.3031L23.4296 9.34061L24.0971 9.13031L25.2782 25.427H31.5976L33.8217 3.05746L21.3595 0Z"
                      fill="#E6E6E6"></path>
                    <path
                      d="M105.462 31.1253L99.4029 30.09L99.4286 29.2908L105.228 29.074L105.08 27.5469H97.6249L97.2429 31.3809L103.405 32.4615L103.434 33.1668L97.0215 33.4192L96.8642 34.9463H105.838L105.462 31.1253ZM47.5673 30.5526L47.4742 31.9374L49.6823 32.0118L49.6534 33.416L45.035 33.2801L45.2372 29.2196L51.5887 29.0708L51.4956 27.5437H43.2923L42.5573 34.9431H51.9032L51.6625 30.4815L47.5673 30.5526ZM87.1332 33.2704L87.0883 31.9956L91.4595 31.9439L91.5013 30.5526L87.0369 30.4976L86.992 29.2067L92.8749 29.0676L92.923 27.5437H84.9893L84.7518 34.9431H93.0707L93.0129 33.416L87.1332 33.2704Z"
                      fill="#E6E6E6"></path>
                    <path
                      d="M80.7274 34.9431L79.9924 27.5437H77.8806L74.8445 31.0703L71.8116 27.5437H69.6998L68.9648 34.9431H71.4457L71.6832 30.145L74.8445 33.416L78.009 30.145L78.2465 34.9431H80.7274Z"
                      fill="#E6E6E6"></path>
                    <path
                      d="M70.2996 0.453735H65.3346L64.882 23.3313H70.8805L70.2996 0.453735Z"
                      fill="#E6E6E6"></path>
                    <path
                      d="M90.4879 0.461792H75.0088L74.4055 23.3296H91.5887L90.4879 0.461792ZM85.6866 18.3536L80.2081 18.5315L80.0252 5.07548L85.754 5.25019L85.6866 18.3536Z"
                      fill="#E6E6E6"></path>
                    <path
                      d="M109 5.4589L108.448 0.453735H93.1935L93.3219 4.91212L98.2035 5.0836L98.7009 23.3313H104.712L102.915 5.2486L109 5.4589Z"
                      fill="#E6E6E6"></path>
                    <path
                      d="M55.6236 23.3313H62.1387L57.8927 13.0621L61.2818 11.1953L58.9646 0.453735H42.3301L41.7845 5.41037L45.5042 5.28419L43.7101 23.3313H49.5321L49.6893 17.5787L53.8841 15.2686L55.6236 23.3313ZM50.0327 5.13213L54.818 4.97036L55.3636 9.52904L49.8402 12.1594L50.0327 5.13213Z"
                      fill="#E6E6E6"></path>
                    <path
                      d="M62.9415 34.9431H65.4545L63.4679 27.5437H57.5721L55.5855 34.9431H58.0985L58.4868 33.0762L62.566 33.128L62.9415 34.9431ZM58.7917 31.6074L59.2956 29.1873L61.7187 29.0773L62.2258 31.5071L58.7917 31.6074Z"
                      fill="#E6E6E6"></path>
                  </svg>
                </a>
                <a className="logo-vng" href="#" target="_blank"><img
                  style={{ width: 60, marginBottom: -10, marginLeft: 20 }}
                  src={logo_vng} /></a>
              </div>
            </div>
            {/* <div className="riotbar-social-links">
              <ul>
                <li>
                  <a
                    className="link-facebook link-social"
                    href="https://www.facebook.com/TocChien"
                    target="_blank">
                    <svg className="" viewBox="0 0 24 24">
                      <path
                        d="M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12c0 5.99 4.388 10.954 10.125 11.854V15.47H7.078V12h3.047V9.356c0-3.007 1.792-4.668 4.533-4.668 1.313 0 2.686.234 2.686.234v2.953H15.83c-1.491 0-1.956.925-1.956 1.874V12h3.328l-.532 3.469h-2.796v8.385C19.612 22.954 24 17.99 24 12z"
                        fill="#FFF"></path>
                    </svg>
                    <span className="image-alt-text"></span>
                  </a>
                </li>
                <li>
                  <a
                    className="link-youtube link-social"
                    href="https://www.youtube.com/c/TocChien"
                    target="_blank">
                    <svg className="" viewBox="0 0 24 24">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm5.47-16.231c.602.148 1.077.583 1.237 1.136C19 9.908 19 12 19 12s0 2.092-.293 3.095c-.16.553-.635.988-1.238 1.136C16.38 16.5 12 16.5 12 16.5s-4.378 0-5.47-.268c-.602-.149-1.077-.584-1.237-1.137C5 14.092 5 12 5 12s0-2.092.293-3.095c.16-.553.635-.988 1.237-1.136C7.622 7.5 12 7.5 12 7.5s4.378 0 5.47.269zM14.226 12l-3.659-1.9v3.8l3.66-1.9z"
                        fill="#FFF"></path>
                    </svg>
                    <span className="image-alt-text"></span>
                  </a>
                </li>
              </ul>
            </div> */}
            <div className="riotbar-legal-links">
              <ul>
                <li>
                  <a className="" href="/thoa-thuan-nguoi-dung.html" target="_blank">Điều Khoản Sử Dụng</a>
                </li>
                <li>
                  <a className="" href="https://hotro.zing.vn/tin-noi-bat-chi-tiet/2/c0b2673a004e3012c270b5742b786336" target="_blank">Giải Quyết Khiếu Nại</a>
                </li>
              </ul>
            </div>
            <div className="vng-text-address">
              <div className="vng-game-text-wrapper">
                <p>Công Ty Cổ Phần VNG<br />
Địa chỉ: Lô Z06 đường số 13, khu chế xuất Tân Thuận, Tân Thuận Đông, quận 7, TP. Hồ Chí Minh <br />
Bản quyền trò chơi thuộc Riot Game Inc. và Công Ty Cổ Phần VNG phân phối độc
                            quyền tại Việt Nam<br />
                            Quyết định phê duyệt nội dung kịch bản trò chơi điện tử G1 trên mạng số:
                            1997/QĐ-BTTTT do Bộ Thông tin và Truyền thông cấp ngày 12/11/2020</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

