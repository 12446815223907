import React, { useMemo } from "react";
import Icon from "../../Icon/Icon";
import { icons } from "../../Icon/icons";
import { ButtonProps } from "../PrimaryButton/PrimaryButton";
import styles from "./SecondaryButton.module.scss";

interface SecondaryButtonProps extends ButtonProps {
  icon?: keyof typeof icons;
  hideCustomCursor?: boolean;
  ariaLabel?: string;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  href,
  icon,
  onClick,
  className = "",
  hideCustomCursor = true,
  ariaLabel,
  theme = "",
}) => {
  const classes = useMemo(() => `${styles.secondaryButton} ${className}`, [className]);
  const child = useMemo(() => (icon ? <Icon name={icon} /> : null), [icon]);

  if (href) {
    return (
      <a
        href={href}
        onClick={onClick}
        className={classes}
        data-theme={theme}
        data-icon={icon}
        target="_blank"
        rel="noopener noreferrer"
        data-hide-custom-cursor={hideCustomCursor}
      >
        {child}
      </a>
    );
  }

  return (
    <button
      className={classes}
      data-theme={theme}
      data-icon={icon}
      onClick={onClick}
      aria-label={ariaLabel}
      data-hide-custom-cursor={hideCustomCursor}
    >
      {child}
    </button>
  );
};

export default SecondaryButton;
