import React, { useEffect } from "react";
// import SecondaryButton from "../../general/button/SecondaryButton/SecondaryButton";

import styles from "./DetachedModal.module.scss";
import SecondaryButton from "../../general/button/SecondaryButton/SecondaryButton";

export interface DetachedModalProps {
  popupRef: React.MutableRefObject<HTMLDivElement | null>;
  isYoutubePopup?: boolean;
  onClickOutside?: () => void; // Called when clicked outside of the modal
  onCloseButtonClick?: () => void;
  open?: boolean;
  showCloseButton?: boolean
}

const DetachedModalPopup: React.FC<DetachedModalProps> = ({
  children,
  popupRef,
  onClickOutside,
  onCloseButtonClick,
  isYoutubePopup = false,
  open = false,
  showCloseButton = true,
}) => {
  useEffect(() => {
    const clickListener = (evt: Event) => {
      if (evt.target === popupRef.current) {
        // Box itself was clicked, do not close
        return;
      } else {
        let checkEl = evt.target as HTMLElement;
        if (checkEl.parentElement) {
          while (checkEl.parentElement) {
            checkEl = checkEl.parentElement;
            if (checkEl === popupRef.current) {
              // clicked inside the box
              return;
            }
          }
        }
        // If we clicked anywhere outside of the box, we close
        onClickOutside?.call(self);
      }
    };

    if (open) {
      document.addEventListener("click", clickListener, true);
    } else {
      document.removeEventListener("click", clickListener, true);
    }
    return () => {
      document.removeEventListener("click", clickListener, true);
    };
  }, [popupRef, onClickOutside, open]);

  if (!open) {
    return null;
  }
  return (
    <div className={styles.popupBackdrop}>
      <div className={`container ${isYoutubePopup ? styles.youtubePopupWrapper : styles.popupWrapper}`}>
        <div className={`content-wrapper ${styles.popupInner}`}>
          {showCloseButton && <SecondaryButton className={styles.closeButton} onClick={onCloseButtonClick} icon="Close" />}
          {children}
        </div>
      </div>
    </div>
  );
};

export default DetachedModalPopup;
