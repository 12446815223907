import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { ContentstackAsset } from "../data/types/Contentstack";
import { isRTL } from "../utils/isRTL";
import bowser from "bowser";
import { LayoutData } from "./Layout";
import { capitalizeChampionName } from "../utils/string";

type SEOProps = {
  lang: string;
  location: Location;
  favicon: ContentstackAsset;
  pageData?: LayoutData;
};

export const SEO: React.FC<SEOProps> = ({ lang, pageData, location, favicon }) => {
  const [bowserData, setBowserData] = useState<bowser.Parser.Parser | null>(null);
  const browser = useMemo(() => bowserData?.getBrowserName(), [bowserData]);
  const device = useMemo(() => bowserData?.getPlatformType(), [bowserData]);
  const articleMetadata = pageData?.articleMetadata;
  const championMetadata = pageData?.championMetadata;
  const pageMetadata = pageData?.pageMetadata;

  const isArticlePage = !!articleMetadata;
  const isChampionPage = !!championMetadata;

  let title = pageMetadata?.title || "";
  let description = pageMetadata?.description || "";
  let imageUrl = pageMetadata?.image_url || "";

  if (isArticlePage && articleMetadata) {
    title = articleMetadata.title;
    description = articleMetadata.description;
    imageUrl = articleMetadata?.banner?.banner?.url;
  } else if (isChampionPage && championMetadata && championMetadata.nodes && championMetadata.nodes.length > 0) {
    title = `${capitalizeChampionName(championMetadata.nodes[0].title)}, ${championMetadata.nodes[0].subtitle}`;
    imageUrl = championMetadata.image.url;
  }
  useEffect(() => {
    setBowserData(bowser.getParser(window.navigator.userAgent));
  }, []);

  return (
    <Helmet
      htmlAttributes={{
        lang,
        dir: isRTL(lang) ? "rtl" : "ltr",
        browser,
        device,
      }}
      title={title}
      link={[{ rel: "icon", type: "image/svg", href: favicon.url }]}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:url`,
          content: location.href,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          property: `og:image:secure_url`,
          content: imageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
      ]}
    />
  );
};
