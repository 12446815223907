// import { hidePoster } from "./animations";
import { gsap, Power1 } from "gsap";
import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import Locale from "../../../data/contexts/riot-locale";
import Icon from "../Icon/Icon";
import styles from "./YoutubeVideo.module.scss";

interface YoutubeVideoProps extends React.HTMLAttributes<HTMLDivElement> {
  videoId: string;
  hidePoster?: boolean;
  title?: string;
  className?: string;
  isModal?: boolean;
}

const YoutubeVideo: React.FC<YoutubeVideoProps> = ({
  videoId,
  hidePoster = false,
  title = "Youtube iframe",
  className = "",
  isModal = false,
}) => {
  const locale = useContext(Locale);
  const youtubePath = useMemo(() => `https://www.youtube.com/embed/${videoId}?hl=${locale.slice(0, 2)}`, [
    videoId,
    locale,
  ]);
  const posterImageUrl = useMemo(() => `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`, [videoId]);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const posterContainerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLSpanElement>(null);

  const handlePlayClick = useCallback(() => {
    const posterElement = posterContainerRef?.current;
    if (posterElement) {
      gsap.fromTo(
        [posterElement?.childNodes],
        { opacity: 1 },
        {
          opacity: 0,
          display: "none",
          pointerEvents: "none",
          duration: 0.6,
          stagger: 0.1,
          ease: Power1.easeOut,
          onStart: () => {
            if (iframeRef?.current) iframeRef.current.src = `${youtubePath}&autoplay=1`;
          },
        },
      );
    }
  }, [posterContainerRef, iframeRef, youtubePath]);

  useEffect(() => {
    isModal && handlePlayClick();
  }, [isModal, handlePlayClick]);

  return (
    <div className={`${className} ${styles.youtubeVideo} ${isModal ? `modal-content ${styles.modalContent}` : ""}`}>
      <iframe
        src={youtubePath}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="autoplay"
        title={title}
        ref={iframeRef}
      />
      {!hidePoster && (
        <div ref={posterContainerRef} className={styles.posterContainer} data-element="poster">
          <Icon className={styles.playButton} name="PlayCircleThin" onClick={handlePlayClick} />
          <img src={posterImageUrl} alt="youtube poster" className={styles.posterImage} />
          <span ref={overlayRef} className={styles.overlay} />
        </div>
      )}
    </div>
  );
};

export default YoutubeVideo;
