// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-champion-detail-tsx": () => import("./../../../src/templates/ChampionDetail.tsx" /* webpackChunkName: "component---src-templates-champion-detail-tsx" */),
  "component---src-templates-champions-tsx": () => import("./../../../src/templates/Champions.tsx" /* webpackChunkName: "component---src-templates-champions-tsx" */),
  "component---src-templates-game-overview-tsx": () => import("./../../../src/templates/GameOverview.tsx" /* webpackChunkName: "component---src-templates-game-overview-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/Homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/NewsArticle.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/News.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-support-page-tsx": () => import("./../../../src/templates/SupportPage.tsx" /* webpackChunkName: "component---src-templates-support-page-tsx" */)
}

