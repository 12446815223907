import { Store } from "use-global-hook";
import { StoreState, StoreActions } from "..";
import { ContentstackAppstoreData } from "../../data/types/Contentstack";

export type AppStoreSection = {
  appStoreData: ContentstackAppstoreData;
  ctaText: string;
  ctaEnabled: boolean;
};

type AppState = {
  registrationModalActiveState: boolean;
  youtubeModalVideoId: string;
  appStoreSection: AppStoreSection;
};

// initialState
const initialState: AppState = {
  registrationModalActiveState: false,
  youtubeModalVideoId: "",
  appStoreSection: {
    appStoreData: {
      title: "",
      isAppleEnabled: false,
      appleStoreUrl: "",
      appleStoreBadge: {
        url: "",
        title: "",
      },
      isGoogleEnabled: false,
      googleStoreUrl: "",
      googleStoreBadge: {
        url: "",
        title: "",
      },
    },
    ctaText: "",
    ctaEnabled: false,
  },
};

// actions
const setRegistrationModalActiveState = (
  store: Store<StoreState, StoreActions>,
  registrationModalActiveState: boolean,
) => {
  store.setState({ ...store.state, registrationModalActiveState });
};

const setYoutubeModalVideoId = (store: Store<StoreState, StoreActions>, youtubeModalVideoId: string) => {
  store.setState({ ...store.state, youtubeModalVideoId });
};

const setAppStoreData = (store: Store<StoreState, StoreActions>, appStoreSection: AppStoreSection) => {
  store.setState({ ...store.state, appStoreSection });
};

export default {
  actions: {
    setRegistrationModalActiveState,
    setYoutubeModalVideoId,
    setAppStoreData,
  },
  initialState,
};
