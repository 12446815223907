import { useEffect, useState, MutableRefObject } from "react";
import { useInView, IntersectionOptions } from "react-intersection-observer";
import { gsap } from "gsap";
export const useScrollObserver = <ElementType>(
  animationMethod: (arg: ElementType) => gsap.core.Timeline | undefined,
  animationReference: MutableRefObject<ElementType>,
  intersectionOptions?: IntersectionOptions,
  delaySeconds?: number,
) => {
  const [animationTimeline, setAnimationTimeline] = useState<gsap.core.Timeline | undefined>(undefined);

  useEffect(() => {
    animationReference && setAnimationTimeline(animationMethod(animationReference.current));
  }, [animationMethod, animationReference]);

  const [oberserverRef, inView] = useInView(
    intersectionOptions
      ? {
          threshold: 0,
          triggerOnce: false,
          ...intersectionOptions,
        }
      : {
          threshold: 0,
          triggerOnce: false,
        },
  );

  useEffect(() => {
    const delay = !!delaySeconds ? delaySeconds : 0;
    gsap.delayedCall(delay, () => {
      if (!!animationTimeline && inView) {
        animationTimeline.play(0);
      }
    });
  }, [inView, animationTimeline, delaySeconds]);

  return oberserverRef;
};

// export const useScrollObserver = (animationTimeline: gsap.core.Timeline | undefined) => {
//   const [oberserverRef, inView] = useInView({
//     threshold: 0,
//   });

//   useEffect(() => {
//     if (animationTimeline && inView) {
//       console.log(animationTimeline);
//       animationTimeline.play(0);
//     }
//   }, [inView, animationTimeline]);

//   return oberserverRef;
// };
