import React, { useMemo, useRef } from "react";
import { Link } from "gatsby-plugin-intl";
import styles from "./PrimaryButton.module.scss";
import { ButtonColorTheme, ButtonColorThemeOption } from "../../../../data/enum/ColorTheme";
import { animateButton } from "./animations";
import { useScrollObserver } from "../../../../hooks/useScrollObserver";
import Icon from "../../../general/Icon/Icon";
import { icons } from "../../../general/Icon/icons";

export interface ButtonProps {
  className?: string;
  href?: string;
  onClick?: () => void;
  ariaLabel?: string;
  theme?: ButtonColorThemeOption;
}

type ButtonType = "button" | "submit" | "reset";
type ButtonTargetType = "_blank" | "_self";

export interface PrimaryButtonProps extends ButtonProps {
  label: string;
  enabled?: boolean;
  target?: ButtonTargetType;
  to?: string;
  type?: ButtonType;
  delaySeconds?: number;
  icon?: keyof typeof icons | null;
  hasWhiteBackground?: boolean;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  label,
  href,
  to,
  onClick,
  className = "",
  type = "button",
  target = "_blank",
  theme = ButtonColorTheme.DEFAULT,
  enabled = true,
  delaySeconds = 0,
  icon,
  hasWhiteBackground = false,
}) => {
  const buttonRef = useRef<HTMLAnchorElement | HTMLButtonElement | null>(null);

  const observerRef = useScrollObserver<HTMLAnchorElement | HTMLButtonElement | null>(
    animateButton,
    buttonRef,
    {},
    delaySeconds,
  );

  const classes = useMemo(
    () => `button ${className}
    ${!enabled ? styles.disabled : ""}`,
    [className, enabled],
  );

  const child = useMemo(
    () => (
      <div className={`label-01 ${styles.label} ${icon ? styles.iconWrapper : ""}`}>
        <span className={styles.labelText}>{label}</span>
        {icon && <Icon className={styles.icon} name={icon} />}
      </div>
    ),
    [label, icon],
  );

  if (to) {
    return (
      <div ref={observerRef} className={classes}>
        <Link
          className={`${styles[theme]} ${hasWhiteBackground ? styles.hasWhiteBackground : ""} ${styles.primaryButton}`}
          innerRef={(el) => (buttonRef.current = el)}
          to={to === "/" ? "/" : `/${to}`}
          onClick={onClick}
        >
          <span className={`${styles.animatedBorder} ${styles.top}`} />
          <span className={styles.background} />
          {child}
          <span className={`${styles.animatedBorder} ${styles.bottom}`} />
        </Link>
      </div>
    );
  } else if (href) {
    return (
      <div ref={observerRef} className={classes}>
        <a
          ref={(el) => (buttonRef.current = el)}
          href={enabled ? href : undefined}
          className={`${styles[theme]} ${hasWhiteBackground ? styles.hasWhiteBackground : ""} ${styles.primaryButton}`}
          onClick={onClick}
          target={target}
          rel="noopener noreferrer"
        >
          <span className={`${styles.animatedBorder} ${styles.top}`} />
          <span className={styles.background} />
          {child}
          <span className={`${styles.animatedBorder} ${styles.bottom}`} />
        </a>
      </div>
    );
  } else {
    return (
      <div ref={observerRef} className={classes}>
        <button
          className={`${styles[theme]} ${hasWhiteBackground ? styles.hasWhiteBackground : ""} ${styles.primaryButton}`}
          ref={(el) => (buttonRef.current = el)}
          type={type}
          onClick={onClick}
        >
          <span className={`${styles.animatedBorder} ${styles.top}`} />
          <span className={styles.background} />
          {child}
          <span className={`${styles.animatedBorder} ${styles.bottom}`} />
        </button>
      </div>
    );
  }
};

export default PrimaryButton;
