import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import RiotBarLoader from "../../riotgame/riotbar-react";
import siteConfig from "../../../config/siteConfig";
import styles from "./RiotBar.module.scss";
import useGlobal from "../../../store";
import { trackEvent } from "../../../utils/analytics";
import rsoConfig from "../../../services/rso/config";
import { convertAnchorsToNavigateLinks } from "../../../utils/riotbar";

interface RiotBarProps {
   locale: string;
}

export const RiotBar: FunctionComponent<RiotBarProps> = ({ locale }) => {
   const [hasLoaded, setHasLoaded] = useState(false);
   const [dropdownActive, setDropdownActive] = useState(false);

   const [, { setRegistrationModalActiveState }] = useGlobal();

   const toogleMenu = () => {
      setDropdownActive(true);
   }
   const closeMenu = () => {
      setDropdownActive(false);
   }

   const handleRiotBarLoaded = useCallback(() => {
      window.RiotBar.account.login = () => {
         trackEvent("cbRegistration");
         setRegistrationModalActiveState(true);
      };
      window.RiotBar.account.openModal = () => {
         setRegistrationModalActiveState(true);
         trackEvent("cbRegistration");
      };
      window.RiotBar.account.logout = async () => {
         sessionStorage.clear();
         // redirect to logout
         window.location.href = `${(await rsoConfig.instance.get()).end_session_endpoint
            }?post_logout_redirect_uri=https://${window.location.hostname}/&client_id=${rsoConfig.RSO_CLIENT_ID}`;
      };
      setHasLoaded(true);
   }, [setRegistrationModalActiveState]);

   useEffect(() => {
      if (!hasLoaded) return;
      convertAnchorsToNavigateLinks();
   }, [hasLoaded]);

   return (
      <header className={styles.header}>
         <div className="riotbar-root">
            <div id="riotbar-bar-wrapper" className="">
               <div id="riotbar-bar" className={dropdownActive ? "i18n-en riotbar-mobile-responsive lor-theme riotbar-base-element sidebar-open" : "i18n-en riotbar-mobile-responsive lor-theme riotbar-base-element"} lang="en">
                  <div id="riotbar-left-content" className="riotbar-left-content">
                     <div id="riotbar-navmenu" className="wildrift-cb3-theme riotbar-branding-switcher">
                        <span style={{ display: "inline-block" }}>
                           <span className="riotbar-navbar-separator main-logo-separator"></span>
                           <span className="second-logo">
                              <a href="/vi-vn/" data-interaction-id="riotbar_app-switcher_theme-logo">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32.5" viewBox="0 0 32 32.55" fill="none">
                                    <path className="cls-1" fill="#00d6ff" d="M31.56,5.17,17.12,17,23,11s.73-.74,1.63-1.62c.3-.3.1-1.63,1.19-2.49,1.44-1.15,3.28-2.38,3.26-2.39-1.52-1.52-3-1.84-5.43-2.06L14.36,12s5.21-3.52,5.44-3c.48,1.19-8.28,10.58-8.28,10.58v1.95h0l0,3.49,12.57-6.73s-6.24,2.45-6.46,1.5c-.44-1.87,13.79-9.35,13.79-9.35A2.6,2.6,0,0,1,31.5,8.1C32,7.34,32.26,6.86,31.56,5.17Z" transform="translate(0 0.08)"></path>
                                    <path className="cls-1" fill="#00d6ff" d="M30.08,14.2c0-.29-.24-1.33-.32-1.66L22.27,17.2Z" transform="translate(0 0.08)"></path>
                                    <path className="cls-2" fill="#c89b3c" d="M1.81,9.84a15,15,0,0,0,0,14.28Z" transform="translate(0 0.08)"></path>
                                    <path className="cls-2" fill="#c89b3c" d="M10.13-.08H1.55L3.17,3.28V29.13L1.55,32.48H21.23L23,26.13H10.13Z" transform="translate(0 0.08)"></path>
                                    <path className="cls-2" fill="#c89b3c" d="M18.84,4.84l1.49-1.9A15.05,15.05,0,0,0,15,2a15.31,15.31,0,0,0-3.54.42V4.76a12.79,12.79,0,0,1,7.35.08Z" transform="translate(0 0.08)"></path>
                                    <path className="cls-2" fill="#c89b3c" d="M30,16.25l-2.28,1.13A12.61,12.61,0,0,1,24.7,25.2l-.37,1.29-.8,2.87A15,15,0,0,0,30.06,17C30.06,16.74,30.05,16.49,30,16.25Z" transform="translate(0 0.08)"></path>
                                 </svg>
                              </a>
                           </span>
                        </span>
                     </div>
                  </div>
                  <div id="riotbar-bar-content" className="riotbar-bar-content" style={{ width: "auto" }}>
                     <div id="riotbar-navbar" className="fade-in ready">
                        <div className="riotbar-explore-label">
                           <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32.5" viewBox="0 0 32 32.55" fill="none"><path className="cls-1" fill="#00d6ff" d="M31.56,5.17,17.12,17,23,11s.73-.74,1.63-1.62c.3-.3.1-1.63,1.19-2.49,1.44-1.15,3.28-2.38,3.26-2.39-1.52-1.52-3-1.84-5.43-2.06L14.36,12s5.21-3.52,5.44-3c.48,1.19-8.28,10.58-8.28,10.58v1.95h0l0,3.49,12.57-6.73s-6.24,2.45-6.46,1.5c-.44-1.87,13.79-9.35,13.79-9.35A2.6,2.6,0,0,1,31.5,8.1C32,7.34,32.26,6.86,31.56,5.17Z" transform="translate(0 0.08)"></path> <path className="cls-1" fill="#00d6ff" d="M30.08,14.2c0-.29-.24-1.33-.32-1.66L22.27,17.2Z" transform="translate(0 0.08)"></path> <path className="cls-2" fill="#c89b3c" d="M1.81,9.84a15,15,0,0,0,0,14.28Z" transform="translate(0 0.08)"></path> <path className="cls-2" fill="#c89b3c" d="M10.13-.08H1.55L3.17,3.28V29.13L1.55,32.48H21.23L23,26.13H10.13Z" transform="translate(0 0.08)"></path> <path className="cls-2" fill="#c89b3c" d="M18.84,4.84l1.49-1.9A15.05,15.05,0,0,0,15,2a15.31,15.31,0,0,0-3.54.42V4.76a12.79,12.79,0,0,1,7.35.08Z" transform="translate(0 0.08)"></path> <path className="cls-2" fill="#c89b3c" d="M30,16.25l-2.28,1.13A12.61,12.61,0,0,1,24.7,25.2l-.37,1.29-.8,2.87A15,15,0,0,0,30.06,17C30.06,16.74,30.05,16.49,30,16.25Z" transform="translate(0 0.08)"></path></svg>
                        </div>
                        <div data-link-id="game-overview" className="riotbar-navbar-navitem-container   ">
                           <a
                              className="riotbar-navbar-link "
                              href="/vi-vn/game-overview/"
                              data-riotbar-link-id="game-overview"
                              target="">Tổng quan trò chơi</a>
                        </div>
                        <div data-link-id="news" className="riotbar-navbar-navitem-container   ">
                           <a
                              className="riotbar-navbar-link "
                              href="/vi-vn/news/"
                              data-riotbar-link-id="news"
                              target="">Tin tức</a>
                        </div>
                        <div data-link-id="champions" className="riotbar-navbar-navitem-container   ">
                           <a
                              className="riotbar-navbar-link "
                              href="/vi-vn/champions/"
                              data-riotbar-link-id="champions"
                              target="">TƯỚNG</a>
                        </div>
                        <div data-link-id="support" className="riotbar-navbar-navitem-container   ">
                           <a
                              className="riotbar-navbar-link "
                              href="/vi-vn/hotrokhachhang"
                              data-riotbar-link-id="support"
                              target="_blank">
                              Hỗ trợ
                              <svg
                                 width="7"
                                 height="7"
                                 className="inline-block link-out non-hover"
                                 viewBox="0 0 8 8">
                                 <path d="M7 2.023v3.985h1V0H2v.996h4L0 7.004V8h1l6-5.977z" fill="#7E7E7E"></path>
                              </svg>
                              <svg width="7" height="7" className="inline-block link-out hover" viewBox="0 0 8 8">
                                 <path d="M7 2.023v3.985h1V0H2v.996h4L0 7.004V8h1l6-5.977z" fill="#FFF"></path>
                              </svg>
                           </a>
                        </div>
                        <div data-link-id="event" className="riotbar-navbar-navitem-container   ">
                           <a
                              className="riotbar-navbar-link "
                              href="/giadinhbaton/"
                              data-riotbar-link-id="event"
                              target="">SỰ KIỆN</a>
                        </div>
                     </div>
                  </div>

                  <div id="riotbar-right-content" className="riotbar-right-content riotbar-show-links">
                     <div id="riotbar-mobile-nav">
                        <a id="riotbar-explore" className="riotbar-explore" onClick={toogleMenu}>
                           <svg width="32" height="32" className="" viewBox="0 0 32 32"><circle opacity=".07" cx="16" cy="16" r="16" fill="#F9F9F9"></circle><path fill="#C7C7C7" d="M22 10v2H10v-2zM22 15v2H10v-2zM22 20v2H10v-2z"></path></svg>
                        </a>
                     </div>
                     <div id="riotbar-navmenu-dropdown" className={dropdownActive ? "riotbar-navmenu-dropdown riotbar-click-active" : "riotbar-navmenu-dropdown"}>
                        <div className="sub-menu-header">
                           <a href="/vi-vn/">
                              <span className="sub-menu-header-icon">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32.5" viewBox="0 0 32 32.55" fill="none"><path className="cls-1" fill="#00d6ff" d="M31.56,5.17,17.12,17,23,11s.73-.74,1.63-1.62c.3-.3.1-1.63,1.19-2.49,1.44-1.15,3.28-2.38,3.26-2.39-1.52-1.52-3-1.84-5.43-2.06L14.36,12s5.21-3.52,5.44-3c.48,1.19-8.28,10.58-8.28,10.58v1.95h0l0,3.49,12.57-6.73s-6.24,2.45-6.46,1.5c-.44-1.87,13.79-9.35,13.79-9.35A2.6,2.6,0,0,1,31.5,8.1C32,7.34,32.26,6.86,31.56,5.17Z" transform="translate(0 0.08)"></path> <path className="cls-1" fill="#00d6ff" d="M30.08,14.2c0-.29-.24-1.33-.32-1.66L22.27,17.2Z" transform="translate(0 0.08)"></path> <path className="cls-2" fill="#c89b3c" d="M1.81,9.84a15,15,0,0,0,0,14.28Z" transform="translate(0 0.08)"></path> <path className="cls-2" fill="#c89b3c" d="M10.13-.08H1.55L3.17,3.28V29.13L1.55,32.48H21.23L23,26.13H10.13Z" transform="translate(0 0.08)"></path> <path className="cls-2" fill="#c89b3c" d="M18.84,4.84l1.49-1.9A15.05,15.05,0,0,0,15,2a15.31,15.31,0,0,0-3.54.42V4.76a12.79,12.79,0,0,1,7.35.08Z" transform="translate(0 0.08)"></path> <path className="cls-2" fill="#c89b3c" d="M30,16.25l-2.28,1.13A12.61,12.61,0,0,1,24.7,25.2l-.37,1.29-.8,2.87A15,15,0,0,0,30.06,17C30.06,16.74,30.05,16.49,30,16.25Z" transform="translate(0 0.08)"></path></svg>
                              </span>
                           </a>
                           <a className="sub-menu-close top-sub-menu-close" onClick={closeMenu}>
                              <svg width="32" height="32" className="" viewBox="0 0 32 32"><circle opacity=".07" cx="16" cy="16" r="16" fill="#F9F9F9"></circle><g stroke="#C7C7C7" strokeWidth="2"><path d="M12.007 11.973l8.132 8.132M11.993 20.093l8.131-8.132"></path></g></svg>
                           </a>
                        </div>
                        <div className="riotbar-navmenu-links">
                           <div className="riotbar-navmenu-category">
                              <a className="riotbar-navmenu-link   " href="/vi-vn/game-overview" data-riotbar-link-id="Overview" data-ping-meta="riotbar-content=navigation|riotbar-navigation=News" target="">Tổng quan trò chơi</a>
                              <a className="riotbar-navmenu-link   " href="/vi-vn/news" data-riotbar-link-id="News" data-ping-meta="riotbar-content=navigation|riotbar-navigation=News" target="">Tin tức</a>
                              <a className="riotbar-navmenu-link   " href="/vi-vn/champions" data-riotbar-link-id="Champion" data-ping-meta="riotbar-content=navigation|riotbar-navigation=News" target="">Tướng</a>
                              <a className="riotbar-navmenu-link   " href="/vi-vn/hotrokhachhang" data-riotbar-link-id="Support" data-ping-meta="riotbar-content=navigation|riotbar-navigation=Support" target="">Hỗ trợ</a>
                              <a className="riotbar-navmenu-link   " href="/tetnangluong" data-riotbar-link-id="Event" data-ping-meta="riotbar-content=navigation|riotbar-navigation=Event" target="">Sự kiện</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </header>
   );
};


